import { Jobs } from '../../utils/greenhouse'
import { Position } from './Position'
import React from 'react'


// type Props = {
//   jobs: Jobs
// }

export const Positions = () => (
  <div className="section-open-positions pad-y-t pad-y-b" id="positions">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="section-header">
            <h2 className="section-title">Open positions</h2>
          </div>
          <div className="positions-list-box">
            <ul>
              <li>
                <Position
                  title="Senior Backend Engineer (Kotlin)"
                  salary="22500 - 37000 PLN"
                  location="Poznań"
                  url="/jobs/backend"
                />
              </li>
              <li>
                <Position
                  title="Product Designer (UX/UI)"
                  salary="18000 - 28000 PLN"
                  location="Poznań/Remote"
                  url="/jobs/ux"
                />
              </li>
              <li>
                <Position
                  title="Creator Partnerships"
                  salary=""
                  location="Los Angeles/Remote"
                  url="/jobs/creator-partnerships"
                />
              </li>
              <li>
                <Position
                  title="Supply Chain Lead"
                  salary=""
                  location="Los Angeles/Remote"
                  url="/jobs/supply-chain-lead"
                />
              </li>
            </ul>
            {/* <div>
              Currently, there are no available positions. Please check back later.
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
)

